<template>
  <div>
    <v-card
      class="mb-3 mx-1"
      :color="commentColor"
      :outlined="outlined"
    >
      <v-card-text>
        <div class="d-flex align-start">
          <user-ref
            v-if="showUserInformation"
            hide-group
            hide-fullname
            hide-email
            hide-phone
            :id="createBy"
            :close="false"
            :avatarProps="{xsmall: dense}"
          />
          <div class="flex-grow-1 ml-2">
            <div
              v-if="showUserInformation"
              class="d-flex align-center justify-start"
            >
              <span
                class="secondary--text mr-2"
                v-if="showCreateByLabel"
              >
                {{$t('t.CreateBy')}}
              </span>
              <user-ref
                block-popup
                hide-group
                hide-email
                hide-phone
                :avatar-props="{visible: false}"
                :id="createBy"
                :close="false"
              />
              <div class="d-flex ">
                <calendar-date
                  class="ml-2"
                  :date="createAt"
                  show-time
                />
                <div
                  class="d-flex "
                  v-if="createAt !== updateAt && !dense"
                >
                  <span class="pl-2">
                    {{$t('t.UpdateAt')}}
                  </span>
                  <calendar-date
                    class="ml-1"
                    :date="updateAt"
                    lower-case
                    show-time
                  />
                </div>
              </div>
            </div>
            <div :class="{ 'limit-comment-height': showActionButton || edit }">
              <v-textarea
                class="ma-1"
                v-model="text"
                auto-grow
                dense
                hide-details="auto"
                :readonly="commentIsReadonly"
                :rows="rows"
                :placeholder="placeholder || $t('t.TopicCommentPlaceholder')"
                no-resize
                :filled="filled"
                :color="commentColor ? 'secondary' : 'primary'"
                @keyup.ctrl.enter="sendInternaly"
                ref="topic-comment-card"
                :rules="rules"
              />
            </div>
          </div>
          <v-btn
            icon
            small
            v-if="!edit && showEditButton"
            @click="editComment"
          >
            <v-icon small>{{$icon('i.Pencil')}}</v-icon>
          </v-btn>
          <v-btn
            icon
            small
            v-if="!edit && showEditButton"
            @click="deleteCommentTopic"
          >
            <v-icon small>{{$icon('i.Delete')}}</v-icon>
          </v-btn>
          <v-btn
            icon
            small
            @click="cancelComment"
            v-if="edit"
          >
            <v-icon>{{$icon('i.Undo')}}</v-icon>
          </v-btn>
        </div>
      </v-card-text>
      <v-card-actions v-if="showActionButton || edit">
        <v-spacer />
        <v-btn
          class="mr-2"
          color="primary"
          @click="sendInternaly"
          :disabled="!text || !text.length"
        >
          <v-icon
            class="pr-2"
            small
          >{{$icon('i.Send')}}</v-icon>{{$t('t.Send')}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>

export default {
  components: {
    CalendarDate: () => import('@/components/calendar-date'),
    UserRef: () => import('@/components/documents-ref/user-ref')
  },
  data () {
    return {
      edit: false,
      oldText: null
    }
  },
  methods: {
    async sendInternaly () {
      await this.saveComment()
      if (this.edit) {
        this.edit = false
      } else {
        this.text = ''
      }
      await this.calculate()
    },
    editComment () {
      this.$el.scrollIntoView(false)
      this.edit = true
      this.oldText = this.text
    },
    cancelComment () {
      this.edit = false
      this.text = this.oldText
    },
    deleteCommentTopic () {
      return this.deleteComment()
    },
    calculate () {
      this.$waitFor(() => this.$refs['topic-comment-card']).then(el => el?.calculateInputHeight())
    }
  },
  computed: {
    commentIsReadonly () {
      return this.readonly || this.createBy !== this.$store.getters.currentUser.id || (this.showEditButton && !this.edit)
    },
    contentMaxLength () {
      return 20000
    },
    createBy () {
      return this.userId ?? this.$store.getters.currentUser.id
    },
    rules () {
      return [
        v => (v ?? '').length <= this.contentMaxLength || this.$t('t.GenericMaxLength'),
        v => !this.required || !!v || this.$t('t.IsRequired')
      ]
    },
    text: {
      get () {
        return this.comment
      },
      set (v) {
        this.$emit('update:comment', v)
      }
    }
  },
  watch: {
    text: {
      immediate: true,
      handler: function () {
        this.calculate()
      }
    }
  },
  props: {
    userId: String,
    saveComment: Function,
    commentColor: String,
    deleteComment: {
      type: Function,
      default: function () {
      }
    },
    comment: String,
    createAt: String,
    dense: Boolean,
    filled: Boolean,
    outlined: Boolean,
    placeholder: String,
    readonly: Boolean,
    rows: {
      type: Number,
      default: 1
    },
    required: Boolean,
    showActionButton: Boolean,
    showCreateByLabel: Boolean,
    showEditButton: Boolean,
    showUserInformation: Boolean,
    updateAt: String
  }
}
</script>

<style lang="stylus" scoped>
.v-text-field > .v-input__control > .v-input__slot::before
  border-style none !important

.limit-comment-height
  max-height 20vh
  overflow-y auto
</style>
